import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { get, compact, last } from "lodash";
import PropTypes from "prop-types";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";

import { ContentBlock } from "../../components/Blocks";
import Contents from "../../components/Contents";
import Pages from "../../components/Pages";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import { makeUrl, removePreloader } from "../../helpers";
import { Share } from "../../widgets";
import { TOURS_CONTENT, TOURS_MAX_UPDATE } from "../../queries/queries.graphql";
import EmptyData from "../../components/EmptyData";

export const query = graphql`
	query toursQuery($slug: String!) {
		hasura {
			...ToursContent
		}
	}
`;

export default function TourPage({ location, data, previewData }) {
	const [tour, setTour] = useState(get(data, "hasura.tours[0]", {}));

	const maxUpdated = new Date(get(data, "hasura.tours_aggregate.aggregate.max.updated_at", new Date()));

	const previewPath = get(previewData, "slug", "");
	const currentPath = last(compact(get(location, "pathname", "").split("/")));

	const path = previewPath || currentPath;

	const toursOptions = {
		variables: {
			location: path,
		},
		fetchPolicy: "cache-and-network",
		ssr: false,
	};

	const { loading: maxUpdateLoading, data: maxUpdateData, error: maxUpdateError } = useQuery(TOURS_MAX_UPDATE, toursOptions);

	const [loadTour, { called: tourCalled, loading: tourLoading, error: tourError, data: tourData }] = useLazyQuery(TOURS_CONTENT, toursOptions);

	useEffect(() => {
		const currentMaxUpdated = new Date(get(maxUpdateData, "tours_aggregate.aggregate.max.updated_at", new Date()));

		if (maxUpdateData && !maxUpdateError) {
			if (+currentMaxUpdated !== +maxUpdated) {
				loadTour();
			} else {
				removePreloader();
			}
		} else if (maxUpdateError) {
			console.error("Invalid load TourMaxUpdate", { maxUpdateError });
			removePreloader();
		}
	}, [maxUpdateLoading]);

	useEffect(() => {
		if (tourData && !tourError) {
			setTour(get(tourData, "tours[0]", previewPath ? null : tour));
			removePreloader(tourCalled);
		} else if (tourError) {
			console.error("Invalid load TourData", { tourError });
			removePreloader();
		}
	}, [tourLoading]);

	const slug = get(tour, "slug", "");
	const title_full = get(tour, "title_full", "");
	const main_image_preview = get(tour, "main_image_preview", "");
	const main_image = get(tour, "main_image", "");
	const content_blocks = get(tour, "content_blocks", []);
	const page_title_full = get(tour, "page_title_full", "");
	const page_title_short = get(tour, "page_title_short", "");

	const url = makeUrl.tour({ slug });
	const imageSrc = get(main_image_preview, "src", get(main_image, "src", ""));

	return tour ? (
		<Pages entity={tour} url={url} >
			<ContentBlock key={"breadcrumbs"}>
				<div className="container">
					<Breadcrumbs currentLabel={`Туры / ${title_full}`} />
				</div>
			</ContentBlock>
			<ContentBlock key={"media-content"} mount={content_blocks && !!content_blocks.length}>
				<div className={"container"}>
					<Contents items={content_blocks} />
				</div>
			</ContentBlock>
			<div className={"container"}>
				<Share
					url={url}
					pageTitleShort={page_title_short}
					pageTitleFull={page_title_full}
					imageSrc={imageSrc}
				/>
			</div>
		</Pages>
	) : (
		<EmptyData />
	);
}

TourPage.propTypes = {
	data: PropTypes.object,
	location: PropTypes.object,
	previewData: PropTypes.object,
};

TourPage.defaultProps = {
	data: {},
	location: {},
	previewData: {},
};
